<script setup lang="ts">
import ehLogo from '~/assets/logo-eh.png'

useHead({
  title: `Most Popular Carriers Near You - ${brand.name}`,
})

analytics.page({
  name: 'Carrier TYP',
  category: 'TYP',
  product: 'medicare',
})

const carriers = ref()
const stateCode = ref()
const stateName = computed(() => {
  return states.find((state) => state.code === stateCode.value)?.name
})

const getStateCode = async () => {
  const queryState = query.state?.toUpperCase()

  if (queryState && states.map((state) => state.code).includes(queryState)) {
    stateCode.value = queryState
    return
  }

  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })

  const ipLookupStateCode = ip.lookup?.stateCode

  if (ipLookupStateCode) {
    stateCode.value = ipLookupStateCode
    return
  }

  // TODO: fallback to ask state code modal
  carriers.value = []
}

onMounted(getStateCode)

watch(stateCode, async () => {
  carriers.value = await getCarriers(stateCode.value)
})

const houseAdLink = ref('https://easyhealth.com/call-4-2023-aep-2')

onMounted(() => {
  const params = new URLSearchParams({
    session_id: getSession().id,
  })

  if (query.utm_source) params.set('utm_source', query.utm_source)
  if (query.utm_medium) params.set('utm_medium', query.utm_medium)

  houseAdLink.value = `${houseAdLink.value}?${params.toString()}`
})
</script>

<template>
  <Layout class="container">
    <div class="max-w-2xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        Most Popular Medicare Advantage Carriers
        <span v-if="stateCode"> in {{ stateName || stateCode }} </span>
      </div>

      <template v-if="!carriers">
        <div
          class="border border-gray-300 rounded-md py-6 flex flex-col items-center space-y-1"
        >
          <div class="text-darkBlue font-medium"> Loading… </div>
          <Spinner />
        </div>
      </template>
      <template v-else-if="carriers.length">
        <div class="space-y-2">
          <template v-for="(carrier, i) in carriers">
            <ClickToCall
              :props="{ position: i + 1, carrier: carrier.name }"
              v-slot="{ handleClick }: any"
            >
              <a
                :href="phone.uri"
                class="flex items-center border border-gray-300 rounded-md py-3 px-4"
                @click="handleClick"
              >
                <div>
                  <div class="text-lg font-medium"> {{ carrier.name }} </div>
                  <div>
                    <span class="text-gray-600">
                      {{ carrier.enrollments }} members
                    </span>
                    <span
                      :class="[
                        carrier.change >= 0
                          ? 'text-green-600'
                          : 'text-orange-600',
                        'inline-block ml-2',
                      ]"
                    >
                      {{
                        (carrier.change >= 0 ? '+' : '') + carrier.change + '%'
                      }}
                    </span>
                  </div>
                </div>
                <div
                  class="ml-auto flex flex-col items-center space-y-1 text-blue"
                >
                  <Icon i-heroicons-solid:phone />
                  <div class="font-medium uppercase text-sm"> Agent </div>
                </div>
              </a>
            </ClickToCall>
          </template>
        </div>
      </template>

      <MediaAlphaAd
        :user="{
          zip: forms.v2?.zipcode,
          email: forms.v2?.email,
          phone: forms.v2?.primaryphone,
          contact:
            forms.v2?.firstname &&
            forms.v2?.lastname &&
            `${forms.v2?.firstname} ${forms.v2?.lastname}`,
          currently_insured: forms.v2?.medicareab ? 1 : null,
        }"
      />

      <HouseAds
        :ads="[
          {
            url: houseAdLink,
            logo: ehLogo,
            star: true,
            verified: true,
            title: '$0 Premium Medicare Advantage Plans',
            items: [
              'Some Plans May Offer Additional Coverage for Prescription Drugs, Dental and Vision',
              'Compare Benefits With Licensed Agent',
              'Choose a Plan and Enroll Today',
            ],
            button: 'Shop Online',
          },
        ]"
      />
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10"],
    "robots": "noindex"
  }
}
</route>
